import React from "react"

import Layout from "../components/layout"

const AttributionsPage = () => (
<Layout>
  <div classname="ui vertical center aligned segment">
    <h1>This website uses the following technologies and resources</h1>
    <div className="ui segment"><a href="https://reactjs.org/">React JS</a></div>
    <div className="ui segment"><a href="https://www.gatsbyjs.org/">Gatsby JS</a></div>
    <div className="ui segment"><a href="https://git-scm.com/">Git</a></div>
    <div className="ui segment"><a href="https://semantic-ui.com/">Semantic UI</a></div>
    <div className="ui segment">The image in the home page is a derivative  work based on images created by <a href="https://www.flickr.com/people/159124985@N05/">Jane Boyko</a> and published under a a <a href="https://creativecommons.org/licenses/by/2.0/https://creativecommons.org/licenses/by/2.0/">Attribution 2.0 Generic (CC BY 2.0)</a> license</div>
    <div className="ui segment">Images on this website are published under a <a href="https://creativecommons.org/licenses/by/2.0/https://creativecommons.org/licenses/by/2.0/">Attribution 2.0 Generic (CC BY 2.0)</a> license</div>
    <div className="ui segment">Images by Videoplasty.com [<a href="https://creativecommons.org/licenses/by-sa/4.0">CC BY-SA 4.0</a>] via Wikimedia Commons</div>
    <a href="https://www.freepik.com/free-photos-vectors/banner">Banner vector created by fullvector - www.freepik.com</a>
  </div>
</Layout>
)

export default AttributionsPage
